select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

/* .wallet-adapter-button {
  background-color: #7000FF;
  font-size: 30px;
  font-family: 'Koulen';
  font-weight: 400;
  border-radius: 7px;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.textOrbitron {
  text-shadow: 12px 9px 13px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke: 4px black;
}

.textShadow {
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}

.textFooterShadow {
  text-shadow: 12px 9px 13px rgba(0, 0, 0, 0.25);
}

.boxFilter {
  filter: drop-shadow(0px 4px 13px rgba(0, 0, 0, 0.8));
}

.towerFilter:hover{
  filter: drop-shadow(0px 0px 40px rgb(255, 255, 158));
}

.fauxBoxShadow{
  box-shadow: 0px 0px 27px 4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 0px 27px 4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 27px 4px rgba(0, 0, 0, 0.5);
}

.slick-slider{
  position: absolute !important;
}

#test2:hover + #test1 {
  display: hidden !important;
}