@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Koulen";
  src: local("Koulen"),
    url(./assets/new/Koulen.ttf);
}

@font-face {
  font-family: "Overpass";
  src: local("Overpass"),
    url(./assets/new/overpass-regular.otf);
}

@font-face {
  font-family: "OrbitronExtraBold";
  src: local("OrbitronExtraBold"),
    url(./assets/new/Orbitron-ExtraBold.ttf);
}

@font-face {
  font-family: "Anybody";
  src: local("Anybody"),
    url(./assets/new/Anybody-Regular.ttf);
}

@font-face {
  font-family: "AnybodyBold";
  src: local("AnybodyBold"),
    url(./assets/new/Anybody-Bold.ttf);
}

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
