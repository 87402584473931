.menu {
  visibility: hidden;
  opacity: 0;
  transform: translateY(50%);
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
}

.dropdown {
  visibility: visible;
  opacity: 1;
  transform: translateY(66%);
  transition-delay: 0s, 0s, 0.3s;
  background-color: #F4911DCC;
}

@media only screen and (min-width: 768px) {
  .dropdown {
      transform: translateY(63%);
    }
}

@media only screen and (min-width: 1280px) {
  .menu {
    transition: none;
    visibility: visible;
    opacity: 100;
    transform: none;
  }
}